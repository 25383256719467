import * as React from "react";
import { History } from "history";
import { adjustedPixelRatio, adjustVideoSize } from "./display";
import { keydownHandler, keyupHandler, KeyboardKey } from "./keyInput";
import * as tracing from "./lib/tracing";
import { SpanStatusCode } from "@opentelemetry/api";
import { MTSpan, MTTracer } from "./lib/tracing/client";
import { MTDataChannelEvent } from "./types/Sesssion";
import { usePublishWebRtcStats } from "./hooks/session";
import { useClientStartStreaming } from "./hooks/session";
import { useDebugLogger } from "./webappConfig";
import { useInputHandlers, WebRTCConfig } from "./webrtc";
import { TouchHandler } from "./touchInput";

// Type definitions and enums
export enum EViewMode {
  Tabbed = "Tabbed",
  Kiosk = "Kiosk",
}

export type WebRTCScreenResolution = {
  width: number;
  height: number;
};

export type Status = "open" | "superseded" | "closed";

export type WebRTCClientProps = {
  id: string;
  namespace: string;
  showDebug?: boolean;
  resolution?: WebRTCScreenResolution;
  viewMode: EViewMode;
  onInteractionEvent?: (event: any) => void;
  onStatusChanged?: (status: Status) => void;
  serverUrl?: string | null;
  rightClickDisabled?: boolean;
  parentSpan?: MTSpan;
  tracer?: MTTracer;
  history: History;
  path?: string;
};

export type MouseLocation = {
  button: number;
  x: number;
  y: number;
};

const location = (window as any).location;
let navigatingThroughHistory = false;

export const outerBrowserInfoStyle: React.CSSProperties = {
  position: "fixed",
  right: 0,
  height: "100px",
  color: "white",
  fontSize: "20pt",
};

export const portal: React.CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
};

export const fakeTextInputStyle: React.CSSProperties = {
  opacity: 0,
  position: "absolute",
};

export const videoStyle: React.CSSProperties = {
  objectFit: "contain",
};

export const portalContents: React.CSSProperties = {
  width: "100%",
  height: "100%",
  outline: "0px",
};

export const scrollReceiverStyle: React.CSSProperties = {
  position: "absolute",
  inset: "0px -20px -20px 0px",
  overflow: "scroll",
  overflowX: "scroll",
  zIndex: 1,
};

export const scrollContentStyle: React.CSSProperties = {
  position: "absolute",
  inset: "0px",
  height: 100000,
  width: 100000,
};

export const WebRTCClient: React.FC<WebRTCClientProps> = (props) => {
  const { debugLog } = useDebugLogger();
  const [connectionStatus, setConnectionStatus] =
    React.useState<Status>("open");
  const [superseded, setSuperseded] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<Status>("open");
  const [, setInnerBrowserTime] = React.useState(0);
  const [drift, setDrift] = React.useState(0);
  const [adjustedOuterBrowserTime, setAdjustedOuterBrowserTime] =
    React.useState(0);
  const [, setPath] = React.useState(props.path);
  const [, setKeysDown] = React.useState<KeyboardKey[]>([]);
  const { publish } = usePublishWebRtcStats();
  const { clientStartStreaming } = useClientStartStreaming();

  const inputHandlers = useInputHandlers(props.id);
  const tracer = props.tracer;

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const wait = (ms: number, parentSpan?: MTSpan): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const span = tracer?.startSpan("connection - waiting", parentSpan);
      try {
        await sleep(1000);
        resolve();
      } catch (err) {
        // @ts-ignore
        span?.recordException(err);
        reject(err);
      } finally {
        span?.end();
        await new Promise((resolve) => setTimeout(resolve, ms));
      }
    });
  };

  const connect = React.useCallback(async () => {
    let connected = false;
    const spanMain = tracer?.startSpan("connect", props.parentSpan);

    while (!connected) {
      console.log("Trying to connect...");
      try {
        const { stats } = await inputHandlers.init(config, tracer, spanMain);
        connected = true;
        spanMain?.setAttribute("init.stats.data", stats);
        spanMain?.setStatus({ code: SpanStatusCode.OK });
        spanMain?.addEvent("open");
        console.log("Status - open");
        setStatus("open");
        try {
          stats.on("stats", (ev: any) => {
            publish({ id: props.id, namespace: props.namespace }, ev);
          });
        } catch (err) {
          // @ts-ignore
          spanMain?.recordException(err);
          console.warn("Failed to listen for stats:", err);
        }
        const updateTime = () => {
          setAdjustedOuterBrowserTime(Date.now() + 0 - drift);
          setTimeout(() => updateTime(), 5);
        };

        updateTime();
        setTimeout(() => setPath("https://google.com"), 5000);
      } catch (err) {
        console.error("Failed to connect to server", err);
        // @ts-ignore
        startSpan?.recordException(err);
      }
      await wait(1000, spanMain);
      if (connected) {
        spanMain?.end();
        props.parentSpan?.end();
      }
    }
  }, [setStatus]);

  React.useEffect(() => {
    if (superseded) {
      setStatus("superseded");
    } else if (connectionStatus === "closed") {
      // try to reconnect and let the connect() handle the status change
      console.log("Reconnecting...");
      connect();
    }
    console.log("status", { connectionStatus });
  }, [connect, superseded, connectionStatus]);

  React.useEffect(() => {
    if (props.onStatusChanged) {
      props.onStatusChanged(status);
    }
  }, [props, status]);

  const remotesRef = React.useRef<HTMLDivElement | null>(null);
  const [, setLastMouseDownLocation] = React.useState<MouseLocation | null>(
    null
  );
  const [, setHistoryIndex] = React.useState<number | null>(0);

  const scrollReceiverRef = React.useRef<any>(null);

  const handleReceivedClipboard = (contents: string) => {
    navigator.clipboard.writeText(contents);
  };

  const openFakeKeyboard = () => {
    document.getElementById("fakeinput")!.inputMode = "text";
  };

  const closeFakeKeyboard = () => {
    document.getElementById("fakeinput")!.inputMode = "none";
  };

  const handlePaste = (e: ClipboardEvent) => {
    inputHandlers.handlePaste(e);
  };

  const unloadHandler = () => {
    releaseKeysAndMouse();
  };

  const onResize = () => {
    inputHandlers.handleResize(
      window.innerWidth,
      window.innerHeight,
      window.devicePixelRatio
    );
    adjustVideoSize(videoRef.current);
  };

  const onContextMenu = (e: MouseEvent) => {
    inputHandlers.handleContext(e);
    (props.onInteractionEvent as any)(e);
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const onPopState = (event: PopStateEvent) => {
    setHistoryIndex((curr) => {
      if (curr != null) {
        navigatingThroughHistory = true;
        if (curr > event.state?.usr?.index) {
          inputHandlers.handleBack();
        } else {
          inputHandlers.handleForward();
        }
        setTimeout(() => {
          navigatingThroughHistory = false;
        }, 1000);
      }
      return event.state?.usr?.index;
    });
  };
  const [manualPlayModal, setManualPlayModalVisible] = React.useState(false);

  const touchHandler = React.useMemo(() => new TouchHandler(inputHandlers), [inputHandlers]);

  React.useEffect(() => {
    props.history?.replace(location.href.replace(location.origin, ""), {
      index: 0,
    });

    inputHandlers.registerOnVideoPlaying(() => {
      debugLog("registerOnVideoPlaying");
      clientStartStreaming({ id: props.id, namespace: props.namespace });
    });

    inputHandlers.registerOnPlayBlocked(() => {
      debugLog("registerOnPlayBlocked");
      setManualPlayModalVisible(true);
    });

    inputHandlers.registerOnConnect(() => {
      debugLog("registerOnConnect");
      onResize();
      if (
        (window as any).navigator.userAgentData?.mobile ??
        /iPhone|Android/.test(navigator.userAgent)
      ) {
        inputHandlers.send_traced({ type: "mobile" } as MTDataChannelEvent);
      } else {
        inputHandlers.send_traced({ type: "desktop" } as MTDataChannelEvent);
        if (/Windows/.test(window.navigator.userAgent)) {
          inputHandlers.send_traced({
            type: "os",
            event: { os: "windows" },
          } as MTDataChannelEvent);
        } else if (/Mac/.test(window.navigator.userAgent)) {
          inputHandlers.send_traced({
            type: "os",
            event: { os: "macos" },
          } as MTDataChannelEvent);
        } else if (/Linux|X11/.test(window.navigator.userAgent)) {
          inputHandlers.send_traced({
            type: "os",
            event: { os: "linux" },
          } as MTDataChannelEvent);
        }
      }
      setConnectionStatus("open");
    });

    inputHandlers.registerOnClose(() => {
      debugLog("inputHandlers.registerOnClose");
      setConnectionStatus("closed");
    });

    inputHandlers.registerCallback((message) => {
      debugLog("webrtc message", { message });
      switch (message.type) {
        case "FocusChangeEvent": {
          if (message.takesKeyboardInput) {
            openFakeKeyboard();
          } else {
            closeFakeKeyboard();
          }
          break;
        }
        case "ClipboardCopyPayload": {
          const { contents } = message;
          handleReceivedClipboard(contents);
          break;
        }
        case "NavigationEvent": {
          if (!navigatingThroughHistory) {
            setHistoryIndex(message.index);
            props.history.push(location.href.replace(location.origin, ""), {
              index: message.index,
            });
          }
          break;
        }
        case "Superseded": {
          setSuperseded(true);
          break;
        }
        case "ExtensionTimestampSync": {
          const innerBrowserTime = message.timestamp;
          setInnerBrowserTime(innerBrowserTime);
          const outerBrowserTime = Date.now() + 0;
          setDrift(innerBrowserTime - outerBrowserTime);
          break;
        }
        case "CursorChange": {
          document.body.style.cursor = message.cursor;
          break;
        }
        case "MetaTagsUpdatedEvent": {
          const { title, favicon } = message;

          if (!!title) {
            document.title = title;
          }

          if (!!favicon) {
            var link = document.createElement("link");

            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = favicon;

            var existingFavicon = document.querySelector(
              'link[rel="shortcut icon"]'
            );

            if (existingFavicon) {
              document.head.removeChild(existingFavicon);
            }

            document.head.appendChild(link);
          }
          break;
        }
        case "BrowserPopStateEvent":
          console.log("BrowserPopStateEvent", { message });
          break;
        case "NavigateOuterBrowser":
          window.location.href = message.href;
          break;
        default:
          debugLog("other data channel message", { message });
          break;
      }
    });

    const handleKeyDown = (e: KeyboardEvent) =>
      keydownHandler(e, setKeysDown, inputHandlers);
    const handleKeyUp = (e: KeyboardEvent) =>
      keyupHandler(e, setKeysDown, inputHandlers);

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("beforeunload", unloadHandler);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchstart", touchHandler.handleTouchStart);
    document.addEventListener("touchmove", touchHandler.handleTouchMove, { passive: false });
    document.addEventListener("touchend", touchHandler.handleTouchEnd);
    window.addEventListener("popstate", onPopState);
    window.addEventListener("resize", onResize);
    window.addEventListener("contextmenu", onContextMenu);
    const video = videoRef.current;
    if (video) {
      video.addEventListener("resize", () => adjustVideoSize(video));
      window.addEventListener("load", () => adjustVideoSize(video));
    }

    // Cleanup function
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("beforeunload", unloadHandler);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchstart", touchHandler.handleTouchStart);
      document.removeEventListener("touchmove", touchHandler.handleTouchMove);
      document.removeEventListener("touchend", touchHandler.handleTouchEnd);
      window.removeEventListener("popstate", onPopState);
      window.removeEventListener("resize", onResize);
      window.removeEventListener("contextmenu", onContextMenu);

      if (video) {
        video.removeEventListener("resize", () => adjustVideoSize(video));
        window.removeEventListener("load", () => adjustVideoSize(video));
      }
    };
  }, []);

  const config: WebRTCConfig = {
    id: props.id,
    namespace: props.namespace,
    iceServers: [
      // leave iceServers empty to disable STUN
    ],
    codec: "h264",
    serverUrl:
      props.serverUrl ??
      `wss://sandbox.mirrortab.com/${props.namespace}/${props.id}/ws`,
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    (remotesRef.current as any).focus();
    onResize();
    connect();
  }, [connect]);

  const getMouseCoords = React.useCallback(
    (ev: MouseEvent): { x: number; y: number; button?: number } => {
      return {
        x: Math.floor(
          (ev.pageX - (remotesRef.current as any).offsetLeft) *
            adjustedPixelRatio()
        ),
        y: Math.floor(
          (ev.pageY - (remotesRef.current as any).offsetTop) *
            adjustedPixelRatio()
        ),
        button: typeof ev.button === "number" ? ev.button + 1 : undefined,
      };
    },
    [remotesRef]
  );

  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      inputHandlers.handleMouse(getMouseCoords(e));

      e.preventDefault();
      return false;
    },
    [getMouseCoords, inputHandlers]
  );

  const onMouseDown = React.useCallback(
    (e: MouseEvent) => {
      (remotesRef.current as any).focus();
      document.getElementById("fakeinput")!.focus();

      // Work around for Safari not playing when MacOS' power saving mode is enabled
      const video = document.getElementById("_video") as HTMLVideoElement;
      if (video && video.paused) {
        video.play();
      }

      const coords = getMouseCoords(e);
      setLastMouseDownLocation(coords as MouseLocation);
      inputHandlers.handleMouseDown(coords);
      (props.onInteractionEvent as any)(e);

      e.preventDefault();
      return false;
    },
    [getMouseCoords, inputHandlers, props.onInteractionEvent]
  );

  const onMouseUp = React.useCallback(
    (e: MouseEvent) => {
      setLastMouseDownLocation(null);
      const coords = getMouseCoords(e);

      inputHandlers.handleMouseUp(coords);
      (props.onInteractionEvent as any)(e);

      e.preventDefault();
      return false;
    },
    [getMouseCoords, inputHandlers, props.onInteractionEvent]
  );

  const releaseKeysAndMouse = () => {
    setKeysDown((keysDown) => {
      keysDown.forEach((key) => {
        inputHandlers.send_traced({
          type: "key_release",
          event: key.code,
        } as MTDataChannelEvent);
      });

      return [];
    });

    setLastMouseDownLocation((existing) => {
      if (existing) {
        inputHandlers.send_traced({
          type: "button_release",
          event: {
            button: existing.button,
            x: existing.x,
            y: existing.y,
          },
        } as MTDataChannelEvent);
      }

      return null;
    });
  };

  // Constants for default scroll positions
  const DEFAULT_SCROLL_TOP = 50000;
  const DEFAULT_SCROLL_LEFT = 50000;

  // Use refs to track the last scroll positions
  const lastScrollTopRef = React.useRef<number>(DEFAULT_SCROLL_TOP);
  const lastScrollLeftRef = React.useRef<number>(DEFAULT_SCROLL_LEFT);

  // Ref to store the timestamp of the last scroll reset
  const lastScrollResetTimeRef = React.useRef<number>(Date.now());

  const resetScroll = React.useCallback(() => {
    // Reset the scroll position to the defaults without calculating deltas
    scrollReceiverRef.current?.scroll({
      left: DEFAULT_SCROLL_LEFT,
      top: DEFAULT_SCROLL_TOP,
    });

    // Update the last scroll positions
    lastScrollTopRef.current = DEFAULT_SCROLL_TOP;
    lastScrollLeftRef.current = DEFAULT_SCROLL_LEFT;
  }, []);

  React.useEffect(() => {
    // Set the timestamp after resetting the scroll
    lastScrollResetTimeRef.current = Date.now();

    // Trigger the scroll reset after 100ms of not scrolling
    const timeoutId = setTimeout(resetScroll, 100);
    return () => clearTimeout(timeoutId);
  }, [resetScroll]);

  const handleWheel = React.useCallback((e: React.WheelEvent) => {
    // Ignore events that occur too soon after resetting the scroll position
    if (Date.now() - lastScrollResetTimeRef.current < 100) {
      return;
    }

    // Calculate the new scroll position based on the wheel deltas
    lastScrollLeftRef.current += e.deltaX;
    lastScrollTopRef.current += e.deltaY;

    // Send the scroll deltas
    inputHandlers.sendScroll(Math.round(e.deltaX), Math.round(e.deltaY));

    debugLog("wheel", {
      deltaX: Math.round(e.deltaX),
      deltaY: Math.round(e.deltaY),
    });
  }, [debugLog, inputHandlers]);

  // Attach the wheel handler instead of the scroll handler
  React.useEffect(() => {
    const scrollReceiverElement = scrollReceiverRef.current;
    if (scrollReceiverElement) {
      scrollReceiverElement.addEventListener("wheel", handleWheel);

      return () => {
        scrollReceiverElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, [handleWheel]);

  const videoRef: React.MutableRefObject<HTMLVideoElement | null> =
    React.useRef(null);
  const onPlaying = React.useCallback(() => {
    if (!videoRef.current!.paused) {
      setManualPlayModalVisible(false);
    }
  }, []);

  return (
    <div style={portal}>
      <div
        key={props.id}
        ref={remotesRef}
        onBlur={releaseKeysAndMouse}
        onMouseOut={releaseKeysAndMouse}
        id="remotes"
        tabIndex={-1}
        style={portalContents}
      >
        <div
          className="scroll-receiver"
          ref={scrollReceiverRef}
          style={scrollReceiverStyle}
        >
          <div style={scrollContentStyle} />
        </div>
        <input
          type="text"
          id="fakeinput"
          inputMode="text"
          onBlur={(e) => (e.target.inputMode = "none")}
          style={fakeTextInputStyle}
        />
        <video
          id="_video"
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={videoStyle}
          onPlaying={onPlaying}
        />
      </div>
    </div>
  );
};
