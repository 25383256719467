import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MirrorTabLogo from "../../mt_logo_512x512.png";
import Wordmark from "../../wordmark.svg";
import { Box } from "@mui/system";
import React from "react";
import { HeaderAvatarButton } from "../HeaderAvatarButton";

export const Header: React.FC = () => {
  const pages = [
    {
      title: "Sessions",
      path: "/app/sessions",
    },
    {
      title: "Sites",
      path: "/app/sites",
    },
    {
      title: "Profile",
      path: "/app/profile",
    },
  ];

  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "80px",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "40px" }}
                src={Wordmark}
                alt="MirrorTab text and logo"
              />
            </Box>
            <Box display={"flex"}>
              <Box display={"flex"} flexDirection={"row"}>
                {pages.map((page) => (
                  <MenuItem
                    id={`nav_link_${page.title.toLowerCase()}`}
                    sx={{
                      color: "black",
                      flexGrow: 1,
                      justifyContent: "center",
                    }}
                    key={page.title}
                    onClick={() => navigate(page.path)}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Box>
              <HeaderAvatarButton />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
