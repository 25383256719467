import { OAuthError, useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { EViewMode } from "../WebRTCClient";
import { useConfigContext, useDebugLogger } from "../webappConfig";
import { useTracing } from "../index";
import { MTSpan } from "../lib/tracing/client";
import { mtFetch } from "../lib/utils";

type Coordinates = {
  id: string;
  namespace: string;
};

interface ISession {
  created: number;
  deleted: number | null | undefined;
  coordinates: Coordinates;
  secret: string;
  vncPassword: string;
  urls: string[];
  hostname: string;
  owner: string;
  activeUsers: string[];
  expires: number;
  viewMode: EViewMode | null;
  events: string[] | null;
  webrtcDesktopVersion: string | null;
  slug: string | null;
  ip: string | null;
  locationString: string | null;
  userAgent: string | null;
  udpMin: number;
  udpMax: number;
  timeToUsefulSession: number | null;
}

export type Session = ISession;

export const useSessionsList = () => {
  const {
    webAppConfig: { apiBaseUrl, environment },
  } = useConfigContext();
  const { tracerProvider } = useTracing();
  const API_BASE_URL = apiBaseUrl();
  const ENVIRONMENT = environment();

  const { getAccessTokenSilently } = useAuth0();
  const [sessions, setSessions] = React.useState<Session[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useCallback(async () => {
    setLoading(true);
    const tracer = tracerProvider?.getMTTracer("WebAppSessions");
    const span = tracer?.startSpan("getSessions");

    const data = await mtFetch(
      `${API_BASE_URL}/sessions`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
      tracer,
      span
    );

    const json = await data.json();
    span?.end();
    setSessions(
      json.filter(
        (session: Session) => session.coordinates.namespace === ENVIRONMENT
      )
    );
    setLoading(false);
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    fetch,
    loading,
    sessions,
  };
};

export const usePublishWebRtcStats = (parentSpan?: MTSpan) => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("usePublishWebrtcStats");
  const publish = async (coordinates: Coordinates, stats: any) => {
    const url = `${API_BASE_URL}/session/${coordinates.namespace}/${coordinates.id}/webrtc-stats`;
    await mtFetch(url, {
      method: "POST",
      body: JSON.stringify(stats)
    }, tracer, parentSpan);
  };

  return { publish };
};

export const useDeleteSession = () => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();

  useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const deleteSession = async (namespace: string, id: string) => {
    await fetch(`${API_BASE_URL}/sessions/${namespace}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    });
  };

  return { deleteSession };
};

export const useCreateSession = () => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const { tracerProvider } = useTracing();
  const API_BASE_URL = apiBaseUrl();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const create = async (
    urls: string[],
    namespace: string,
    viewMode: EViewMode,
    webrtcDesktopVersion: string | null,
    slug: string | null = null,
    clientInfoRaw: string,
    quantity: number,
    ttlInSeconds: string,
    codec: string | null,
    siteSlug?: string | null
  ) => {
    const fp = await FingerprintJS.load();

    const { visitorId, components } = await fp.get();

    const tracer = tracerProvider?.getMTTracer("WebAppSessions");
    const span = tracer?.startSpan("createSession");

    const data = await mtFetch(
      `${API_BASE_URL}/sessions/${namespace}/session`,
      {
        method: "POST",
        body: JSON.stringify({
          urls,
          viewMode,
          slug,
          clientInfoRaw,
          userAgent: navigator.userAgent,
          browserFingerprint: {
            visitorId,
            serializedComponents: JSON.stringify(components),
          },
          quantity,
          webrtcDesktopVersion,
          codec,
          ttlInSeconds: parseInt(ttlInSeconds, 10),
          siteSlug,
        }),
        headers: isAuthenticated
          ? {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            }
          : {},
      },
      tracer,
      span
    );

    const json = await data.json();

    span?.end();

    return json as {
      type: string;
      session?: ISession | null;
    };
  };

  return { create };
};

export const useCreateHandshake = () => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("WebAppSessions");
  const API_BASE_URL = apiBaseUrl();
  const { debugLog } = useDebugLogger();

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const createHandshake = async (
    coordinates: Coordinates,
    parentSpan: MTSpan | undefined
  ) => {
    debugLog("useCreateHandshake > createHandshake", { coordinates });
    tracer?.setSessionId(coordinates.id);
    const span = tracer?.startSpan("handshake", parentSpan);
    // api.trace.()

    const response = await mtFetch(
      `${API_BASE_URL}/session/${coordinates.namespace}/${coordinates.id}/handshake`,
      {
        method: "POST",
        headers: isAuthenticated
          ? {
              Authorization: `Bearer: ${await getAccessTokenSilently()}`,
            }
          : {},
      },
      tracer,
      span
    );
    const { key } = await response.json();

    span?.end();
    return { key };
  };

  return {
    createHandshake,
  };
};

export const useReconnectingWebsocket = (
  coordinates: Coordinates,
  handleMessage: (message: any) => any
) => {
  const { createHandshake } = useCreateHandshake();
  const { debugLog } = useDebugLogger();
  const {
    webAppConfig: { controlPlaneWebsocketBaseUrl },
  } = useConfigContext();
  const CP_WSS_BASE_URL = controlPlaneWebsocketBaseUrl();

  const connect = async (parentSpan: MTSpan | undefined) => {
    debugLog("useReconnectingWebsocket > connect", { coordinates });

    const { key } = await createHandshake(coordinates, parentSpan);

    const rws = new ReconnectingWebSocket(
      `${CP_WSS_BASE_URL}/handshake/${key}`
    );

    rws.addEventListener("message", (message) => {
      debugLog("connect > reconnectingWebSocket.addEventListener > onMessage", {
        message,
        json: JSON.parse(message.data),
      });
      handleMessage(JSON.parse(message.data));
    });
  };

  return { connect };
};

export const useWebsocketReady = (setReady: () => any) => {
  const { debugLog } = useDebugLogger();
  const {
    webAppConfig: { sessionWebsocketBaseUrl },
  } = useConfigContext();
  const SESSION_WSS_BASE_URL = sessionWebsocketBaseUrl();
  const poll = async (secret: string) => {
    debugLog("useWebsocketReady > poll", { secret });
    const rws = new ReconnectingWebSocket(
      `${SESSION_WSS_BASE_URL}/${secret}/ws`
    );

    rws.addEventListener("open", (message) => {
      debugLog(
        "useWebsocketReady > reconnectingWebsocket.addEventListener > onOpen",
        { message }
      );
      console.log("open", { message });
      setReady();
      rws.close();
    });
  };

  return { poll };
};

export const useClientStartStreaming = () => {
  const { debugLog } = useDebugLogger();
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("WebAppSessions");

  const API_BASE_URL = apiBaseUrl();
  const clientStartStreaming = async (
    coordinates: Coordinates,
    parentSpan?: MTSpan
  ) => {
    debugLog("useClientStartStreaming > clientStartStreaming", { coordinates });

    const tracer = tracerProvider?.getMTTracer(
      "WebAppSessions",
      coordinates.id
    );

    const span = tracer?.startSpan("startClientStreaming", parentSpan);
    const response = await mtFetch(
      `${API_BASE_URL}/session/${coordinates.namespace}/${coordinates.id}/client-start-streaming`,
      {
        method: "POST",
      },
      tracer,
      span
    );

    if (response.status !== 204) {
      throw Error(`response - ${response.status} - ${await response.text()}`);
    }

    span?.end();
    return;
  };

  return {
    clientStartStreaming,
  };
};
