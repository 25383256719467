import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Slider,
  TextField,
} from "@mui/material";
import { gql } from "../__generated__";
import { useMutation, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { useNavigate } from "react-router-dom";
import { isValidUrl } from "../lib/helper";
import { debounce } from "lodash";
import { blueLineColor } from "./SiteContainer";

const VALIDATE_SLUG_QUERY = gql(`
query ValidateSlug($slug: String!) {
  validateSlug(slug: $slug) {
    valid
  }
}
`);

const CREATE_HOSTED_SITE_MUTATION = gql(`
mutation CreateHostedSiteMutation($slug: String, $title: String!, $description: String, $workspaceId: String!, $scale: Int!) {
  createHostedSite(input: { slug: $slug, title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const CREATE_PROXY_SITE_MUTATION = gql(`
mutation CreateProxySiteMutation($slug: String, $title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {
  createProxySite(input: { slug: $slug, title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const HOSTED = "hosted";
const PROXY = "proxy";
type SiteType = typeof HOSTED | typeof PROXY;

export const SiteCreate: React.FC = () => {
  const navigate = useNavigate();
  const { workspaceId, refetchWorkspace } = useSiteWorkspaceContext();
  const [createHostedSiteMutation] = useMutation(CREATE_HOSTED_SITE_MUTATION);
  const [createProxySiteMutation] = useMutation(CREATE_PROXY_SITE_MUTATION);
  const [validateSlugQuery] = useLazyQuery(VALIDATE_SLUG_QUERY);

  const [saving, setSaving] = useState(false);
  const [validatingSlug, setValidatingSlug] = useState(false);
  const [siteType, _setSiteType] = useState<SiteType | null>(PROXY);
  const [slug, _setSlug] = useState("");
  const [title, _setTitle] = useState("");
  const [description, _setDescription] = useState("");
  const [proxyUrl, _setProxyUrl] = useState("");
  const [scale, _setScale] = useState(1);

  const [slugError, setSlugError] = useState<string | null>(null);
  const [titleError, setTitleError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [proxyUrlError, setProxyUrlError] = useState<string | null>(null);
  const [isSlugValid, setIsSlugValid] = useState(true);

  const debouncedValidateSlug = React.useCallback(
    debounce(async (slug: string) => {
      if (!slug) {
        setSlugError(null);
        setIsSlugValid(true);
        setValidatingSlug(false);
        return;
      }

      setValidatingSlug(true);
      const { data } = await validateSlugQuery({ variables: { slug } });
      setValidatingSlug(false);

      if (data?.validateSlug?.valid) {
        setSlugError(null);
        setIsSlugValid(true);
      } else {
        setSlugError("Slug is already taken or invalid");
        setIsSlugValid(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (slug) debouncedValidateSlug(slug);
  }, [slug, debouncedValidateSlug]);

  const setSiteType = (value: SiteType) => {
    _setSiteType(value);
    if (value === HOSTED) {
      setProxyUrlError(null);
      setProxyUrl("");
    }
  };

  const setSlug = (value: string) => {
    _setSlug(value);
  };

  const setTitle = (value: string) => {
    _setTitle(value);
    setTitleError(value ? null : "Title is required");
  };

  const setDescription = (value: string) => {
    _setDescription(value);
    setDescriptionError(value ? null : "Description is required");
  };

  const setProxyUrl = (value: string) => {
    _setProxyUrl(value);
    setProxyUrlError(value && !isValidUrl(value) ? "Invalid URL" : null);
  };

  const submit = async () => {
    if (!siteType) throw Error("No site type");
    if (!title) throw Error("No title");
    if (!workspaceId) throw Error("No workspace ID");

    setSaving(true);
    const variables = {
      slug: slug || null,
      title,
      description,
      workspaceId,
      scale,
    };

    if (siteType === HOSTED) {
      const result = await createHostedSiteMutation({ variables });
      if (result.data?.createHostedSite.created) {
        navigate(
          `/app/sites/workspaces/${workspaceId}/site/${result.data.createHostedSite.site?.id}`
        );
      }
    } else {
      const result = await createProxySiteMutation({
        variables: { ...variables, proxyUrl },
      });
      if (result.data?.createProxySite.created) {
        navigate(
          `/app/sites/workspaces/${workspaceId}/site/${result.data.createProxySite.site?.id}`
        );
      }
    }
    setSaving(false);
    refetchWorkspace?.();
  };

  const createDisabled =
    saving ||
    validatingSlug ||
    !title ||
    !description ||
    !!titleError ||
    !!descriptionError ||
    !isSlugValid ||
    (siteType === PROXY && (!proxyUrl || !!proxyUrlError));

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      minHeight="80vh"
    >
      <TextField
        value={slug}
        variant="filled"
        onChange={(e) => setSlug(e.target.value)}
        placeholder="Site Slug (optional)"
        error={!!slugError}
        label={slugError}
        fullWidth
        margin="dense"
        sx={{ width: "700px" }}
        InputProps={{
          endAdornment: validatingSlug && <CircularProgress size={20} />,
        }}
      />

      <TextField
        value={title}
        variant="filled"
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Site Title"
        error={!!titleError}
        fullWidth
        sx={{ width: "700px" }}
      />
      <TextField
        value={description}
        variant="filled"
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Site Description"
        error={!!descriptionError}
        fullWidth
        sx={{ width: "700px" }}
      />

      {siteType === PROXY && (
        <TextField
          value={proxyUrl}
          variant="filled"
          onChange={(e) => setProxyUrl(e.target.value)}
          placeholder="Proxy URL"
          error={!!proxyUrlError}
          label={proxyUrlError}
          fullWidth
          margin="dense"
          sx={{ width: "700px" }}
        />
      )}

      <Box width="700px" marginY="20px">
        <label>Scale: {scale}</label>
        <Slider
          value={scale}
          onChange={(_, newValue) => _setScale(newValue as number)}
          step={1}
          marks
          min={0}
          max={20}
          sx={{ width: "700px" }}
        />
      </Box>

      <Button
        size="large"
        disabled={createDisabled}
        variant="contained"
        onClick={submit}
      >
        {saving ? <CircularProgress size={24} /> : "Create Site"}
      </Button>
    </Box>
  );
};
