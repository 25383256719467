import * as React from "react";
import { ConsoleRouter } from "../console/ConsoleRouter";
import {
  ConsoleModeString,
  LocalEngineModeString,
  SlugModeString,
  useConfigContext,
} from "../../webappConfig";
import { createBrowserHistory } from "history";
import { EViewMode, WebRTCClient } from "../../WebRTCClient";
import { SlugSession, SlugSessionWithExplicitSlug } from "../SlugSession";

export const LocalEngineDevelopment: React.FC = () => {
  return (
    <WebRTCClient
      key={"5376230D-00BF-4F28-A48F-CE59A898FB5D"}
      id={"5376230D-00BF-4F28-A48F-CE59A898FB5D"}
      namespace={"local"}
      viewMode={EViewMode.Kiosk}
      onInteractionEvent={() => {}}
      onStatusChanged={() => {}}
      serverUrl={`ws://localhost:3005/ws`}
      history={createBrowserHistory()}
      rightClickDisabled={true}
    />
  );
};

export const Router: React.FC = () => {
  const { webAppConfig } = useConfigContext();

  const mode = webAppConfig.mode();

  if (mode.type == LocalEngineModeString) {
    return <LocalEngineDevelopment />;
  }

  if (mode.type == ConsoleModeString) {
    return <ConsoleRouter />;
  }

  if (mode.type == SlugModeString) {
    return <SlugSessionWithExplicitSlug slug={mode.slug} />;
  }

  return <ConsoleRouter />;
};
