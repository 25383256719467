import { Client } from "ion-sdk-js";
import { Configuration } from "ion-sdk-js/lib/client";
import { IonSFUJSONRPCSignal } from "ion-sdk-js/lib/signal/json-rpc-impl";
import tracing from "./lib/tracing";
import { MTSpan, MTTracer } from "./lib/tracing/client";
import { WebRTCStats } from "@peermetrics/webrtc-stats";
import { MTDataChannelEnvelope, MTDataChannelEvent } from "./types/Sesssion";
import { useTracing } from ".";

let inputDatachannel: RTCDataChannel | null = null;
let callbacks: any[] = [];
let onConnect: any | null = null;
let onPlayBlocked: any = null;
let onClose: any | null = null;
let onVideoPlaying: any | null = null;
// eslint-disable-next-line react-hooks/rules-of-hooks

export type WebRTCConfig = Partial<Configuration> & {
  id: string;
  namespace: string;
  serverUrl: string;
};

export type WebRTCHelloParams = {
  engineVersion: string;
  sessionId: string;
};

class IonSignal extends IonSFUJSONRPCSignal {
  getSocket() {
    return this.socket;
  }
}

export const useInputHandlers = (sessionId: string) => {
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("SessionInputHandlers", sessionId);

  const registerCallback = (cb: (msg: any) => any) => {
    callbacks = [...callbacks, cb];
  };

  const registerOnConnect = (cb: () => any) => {
    onConnect = cb;
  };

  const registerOnPlayBlocked = (cb: () => any) => {
    onPlayBlocked = cb;
  };

  const registerOnClose = (cb: () => any) => {
    onClose = cb;
  };

  const registerOnVideoPlaying = (cb: () => any) => {
    onVideoPlaying = cb;
  };

  const pendingMessages: string[] = [];

  const send_traced = (
    event: MTDataChannelEvent,
    span?: MTSpan,
    reliably?: boolean
  ) => {
    const envelope: MTDataChannelEnvelope = {
      tracing: span?.getMTDataChannelTraceInfo(),
      event: event,
    };

    const payload = JSON.stringify(envelope);
    if (inputDatachannel?.readyState === "open") {
      // debugLog("sending message because readystate=open", { payload });
      inputDatachannel.send(payload);
    } else if (reliably) {
      console.log("data channel not ready, queueing message");
      // debugLog("queue message because reliably=false", { payload });
      pendingMessages.push(payload);
    }
  };

  const handlePaste = (e: any, parentSpan?: MTSpan) => {
    const text = e.clipboardData.getData("text/plain");

    const span = tracer?.startSpan("handlePaste", parentSpan);
    const evt: MTDataChannelEvent = {
      type: "clipboard_paste",
      event: text,
    };

    send_traced(evt, span);
    span?.end();

    e.preventDefault();
    return false;
  };

  const handleMouse = (e: any, parentSpan?: MTSpan) => {
    //const span = tracer.startSpan("handleMouse", parentSpan)
    const evt = {
      type: "move",
      event: {
        x: e.x,
        y: e.y,
      },
    };
    send_traced(evt);
    //span?.end();
  };

  const handleMouseDown = (e: any, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleMouseDown", parentSpan);
    let btnNumber = e.button;
    if (e.button === 5) {
      btnNumber = 9
    } else if (e.button === 4) {
      btnNumber = 8
    }
    const evt = {
      type: "button_press",
      event: {
        button: btnNumber,
        x: e.x,
        y: e.y,
      },
    };
    send_traced(evt, span);
    span?.end();
  };

  const handleMouseUp = (e: any, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleMouseUp", parentSpan);
    let btnNumber = e.button;
    if (e.button === 5) {
      btnNumber = 9
    } else if (e.button === 4) {
      btnNumber = 8
    }
    const evt = {
      type: "button_release",
      event: {
        button: btnNumber,
        x: e.x,
        y: e.y,
      },
    };
    send_traced(evt, span);
    span?.end();
  };

  const debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const handleResize = (
    window_width: number,
    window_height: number,
    devicePixelRatio: number,
    parentSpan?: MTSpan
  ) => {
    const span = tracer?.startSpan("handleResize", parentSpan);
    const evt = {
      type: "resize",
      event: {
        window_width: window_width,
        window_height: window_height,
        device_pixel_ratio: devicePixelRatio,
      },
    };
    send_traced(evt, span, true);
    span?.end();
  };

  const handleNavigateToPath = (path: string, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleNavigateToPath", parentSpan);
    const evt = {
      type: "navigate",
      event: {
        path: path,
      },
    };

    console.log("sending", JSON.stringify(evt));

    send_traced(evt, span);
    span?.end();
  };

  const handleKey = (e: any, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleKey", parentSpan);
    const evt = {
      type: e.type === "keydown" ? "key_press" : "key_release",
      event: e.code,
    };
    send_traced(evt, span);
    span?.end();
  };

  const sendScroll = (x: number, y: number, parentSpan?: MTSpan) => {
    //const span = tracer.startSpan("sendScroll", parentSpan)
    const evt = {
      type: "scroll",
      event: {
        x,
        y,
      },
    };
    send_traced(evt);
    //span?.end();
  };

  const handleForward = (parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleForward", parentSpan);
    console.log("handle forward");
    const evt = {
      type: "forward",
      event: {
        steps: 1,
      },
    };

    send_traced(evt, span);
    span?.end();
  };

  const handleBack = (parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handleBack", parentSpan);
    const evt = {
      type: "back",
      event: {
        steps: 1,
      },
    };

    send_traced(evt, span);
    span?.end();
  };

  const releaseMouse = (mouse: any, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("releaseMouse", parentSpan);
    send_traced(mouse, span);
    span?.end();
  };

  const releaseKeys = (keys: any, parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("releaseKeys", parentSpan);
    keys
      .map((key: any) => ({
        type: "key_release",
        event: key,
      }))
      .forEach((event: any) => send_traced(event, span));
    span?.end();
  };

  const handleContext = (e: any) => {
    e.preventDefault();
  };

  /**
   * For every remote stream this object will hold the follwing information:
   * {
   *  "id-of-the-remote-stream": {
   *     stream: [Object], // Reference to the stream object
   *     videoElement: [Object] // Reference to the video element that's rendering the stream.
   *   }
   * }
   */
  const streams = {};

  /**
   * When we click the Enable Audio button this function gets called, and
   * unmutes all the remote videos that might be there and also any future ones.
   * This little party trick is according to
   * https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide.
   */
  let remoteVideoIsMuted = true;
  function enableAudio() {
    if (remoteVideoIsMuted) {
      // Unmute all the current videoElements.
      for (const streamInfo of Object.values(streams)) {
        const { videoElement } = streamInfo as any;
        videoElement.pause();
        videoElement.muted = false;
        videoElement.play();
      }
      // Set remoteVideoIsMuted to false so that all future autoplays
      // work.
      remoteVideoIsMuted = false;

      const button: any = document.getElementById("enable-audio-button");
      button.remove();
    }
  }

  // Resolves when connected or rejects if it can't connect.
  function init(
    config: WebRTCConfig,
    tracer?: MTTracer,
    parentSpan?: MTSpan
  ): Promise<{ stats: any }> {
    return new Promise((resolve, reject) => {
      const span = tracer?.startSpan("webrtcts - init", parentSpan);
      try {
        // debugLog("webrtc init", { config });

        const stats = new WebRTCStats({
          getStatsInterval: 5000,
        });

        const remotesDiv = document.getElementById("remotes");

        const params = new URLSearchParams(window.location.search);

        const signalLocal = new IonSignal(config.serverUrl);
        const clientLocal = new Client(signalLocal, config as any);

        let connected = false;
        signalLocal.onopen = () => {
          // debugLog("signalLocal.onopen");

          connected = true;
          clientLocal.join(
            (params.has("session")
              ? params.get("session")
              : "test session") as string,
            ""
          );

          if (clientLocal.transports) {
            stats.addConnection({
              pc: clientLocal.transports[1].pc,
              peerId: "1",
              connectionId: config.serverUrl,
              remote: false,
            });
          }

          (clientLocal.transports as any)[1].pc.ondatachannel = (ev: any) => {
            // debugLog("pc.ondatachannel", { ev });

            inputDatachannel = ev.channel;
            (window as any)["dc"] = ev.channel;

            ev.channel.onopen = (openEv: Event) => {
              // debugLog("ev.channel.onopen", { openEv });

              if (onConnect) {
                // debugLog("onConnect = true");
                onConnect();
              }

              // debugLog(`Sending ${pendingMessages.length} pending messages`);
              for (const message of pendingMessages) {
                // debugLog(`Sending message`, { message });
                ev.channel.send(message);
              }
              pendingMessages.length = 0;
            };

            ev.channel.onmessage = (message: any) => {
              // debugLog(`Received message`, {
              //   message,
              //   json: JSON.parse(message.data),
              // });
              callbacks.forEach((cb) => cb(JSON.parse(message.data)));
            };
          };

          const pingInterval = setInterval(async () => {
            signalLocal.notify("ping", {});
          }, 5000);

          signalLocal.onclose = () => {
            // debugLog("signalLocal.onClose");
            connected = false;
            clearInterval(pingInterval);

            localStorage.removeItem("MT__CONNECT_ENGINE_VERSION");
            localStorage.removeItem("MT__CONNECT_SESSION_ID");

            console.log("signal closed");
            if (onClose) {
              onClose();
            }
          };

          signalLocal.onerror = (error) => {
            // debugLog("signalLocal.onerrror", { error });
            clearInterval(pingInterval);
            if (onClose) {
              // debugLog("signalLocal.onerror > onClose", { error });
              onClose();
            }
          };

          signalLocal.on_notify("hello", (params: WebRTCHelloParams) => {
            localStorage.setItem("MT__CONNECT_ENGINE_VERSION", params.engineVersion);
            localStorage.setItem("MT__CONNECT_SESSION_ID", params.sessionId);
          });

          resolve({ stats });
        };

        // If we can't connect to the server, reject the promise.
        signalLocal.getSocket().onclose = () => {
          // debugLog("signalLocal.getSocket().onClose");
          if (!connected) {
            // debugLog("signalLocal.getSocket().onClose > connected=false");
            reject("Could not connect to server");
          } else {
            console.log("Disconnected");
          }
        };

        clientLocal.ontrack = (track, stream) => {
          // debugLog("clientLocal.ontrack", { track, stream });
          track.onended = () => {
            // debugLog("track ended", track.id);
          };
          track.onunmute = async () => {
            // debugLog("clientLocal.lontrack > track.onMute");
            // If the stream is not in the streams map.
            if ((streams as any)[stream.id]?.stream !== stream) {
              const remoteVideo = document.getElementById(
                "_video"
              ) as HTMLVideoElement;
              remoteVideo.srcObject = stream;
              remoteVideo.oncontextmenu = () => {
                return false;
              };
              remoteVideo.muted = true;
              remoteVideo.setAttribute("muted", "true");

              // If this throws, it means that the browser is blocking the autoplay.
              try {
                await remoteVideo.play();
                // debugLog("client started playing video");
                onVideoPlaying();
              } catch (err) {
                // debugLog("catch remoteVideo.play()", { err });
                onPlayBlocked();
              }

              // Save the stream and video element in the map.
              (streams as any)[stream.id] = {
                stream,
                videoElement: remoteVideo,
              };

              // When this stream removes a track, assume
              // that its going away and remove it.
              stream.onremovetrack = (track) => {
                // debugLog("stream.onremovetrack", { track });
                try {
                  // debugLog(
                  //   "stream.onremovetrack try > removing track",
                  //   (track as any).id
                  // );
                  if ((streams as any)[stream.id]) {
                    const { videoElement } = (streams as any)[stream.id];
                    (remotesDiv as any).removeChild(videoElement);
                    delete (streams as any)[stream.id];
                  }
                } catch (err) {
                  // debugLog("stream.onremovetrack catch", { err });
                  console.error("Failed to handle onremovetrack", err);
                }
              };
            }
          };
        };
      } catch (err) {
        // debugLog("webrtc init", { err });

        // @ts-ignore
        span.recordException(err);
        reject(err);
      } finally {
        span?.end();
      }
    });
  }

  return {
    handleBack,
    handleForward,
    init,
    releaseMouse,
    releaseKeys,
    registerCallback,
    registerOnConnect,
    registerOnPlayBlocked,
    registerOnClose,
    registerOnVideoPlaying,
    handleMouse,
    handleMouseDown,
    debounce,
    handleNavigateToPath,
    handleKey,
    sendScroll,
    handleContext,
    send_traced,
    handlePaste,
    handleMouseUp,
    handleResize,
    tracer,
  };
};
