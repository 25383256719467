import { adjustedPixelRatio } from "./display";

// number of animation frames for momentum scrolling
const MOMENTUM_INTERVALS = 90;
// Velocity decay factor per frame (0-1, higher = more inertia)
const MOMENTUM_DECAY_FACTOR = 0.98;

export class TouchHandler {
  private lastTouch: Touch | null = null;
  private velocityX: number = 0;
  private velocityY: number = 0;
  private lastTime: number = 0;
  private inputHandlers: any;
  private activeTimeouts: NodeJS.Timeout[] = [];

  constructor(inputHandlers: any) {
    this.inputHandlers = inputHandlers;
  }

  handleTouchStart = (e: TouchEvent) => {
    // clear any active momentum scrolling
    this.activeTimeouts.forEach(timeoutId => clearTimeout(timeoutId));
    this.activeTimeouts = [];
    
    this.lastTouch = e.touches[0];
    let position = {
      x: e.touches[0].pageX * adjustedPixelRatio(),
      y: e.touches[0].pageY * adjustedPixelRatio(),
    };
    this.inputHandlers.handleMouse(position);
  };

  handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    
    const now = Date.now();
    const touch = e.touches[0];
    
    if (!this.lastTouch || now === this.lastTime) {
      this.lastTouch = touch;
      this.lastTime = now;
      return;
    }

    const { pageX, pageY } = touch;
    const deltaX = this.lastTouch.pageX - pageX;
    const deltaY = this.lastTouch.pageY - pageY;

    // update velocity value during movement
    const timeDelta = now - this.lastTime;
    this.velocityX = deltaX / timeDelta;
    this.velocityY = deltaY / timeDelta;

    this.inputHandlers.sendScroll(Math.round(deltaX), Math.round(deltaY));

    this.lastTouch = touch;
    this.lastTime = now;
  };

  handleTouchEnd = () => {
    this.lastTouch = null;

    // use the tracked velocity for momentum
    if (Math.abs(this.velocityX) > 0.1 || Math.abs(this.velocityY) > 0.1) {
      let currentVelocityX = this.velocityX;
      let currentVelocityY = this.velocityY;

      for (let i = 0; i < MOMENTUM_INTERVALS; i++) {
        const progress = i / MOMENTUM_INTERVALS;
        /* Scroll deceleration formula:
        * delta = v₀ * 16 * (1 - ln(1 + 8p)/ln(9))
        * where:
        * v₀ = initial velocity
        * p = progress (0 to 1)
        * ln(9) ≈ 2.197 is the normalization factor
        * 
        * This creates a logarithmic decay curve that:
        * - Starts at full velocity when p = 0
        * - Decays quickly at first, then more gradually
        * - Approaches zero as p → 1
       * 
       * Stolen from:https://ariya.io/2013/11/javascript-kinetic-scrolling-part-2
       */
        const easeOut = 1 - Math.log1p(progress * 8) / Math.log1p(8);

        const deltaX = currentVelocityX * 16 * easeOut;
        const deltaY = currentVelocityY * 16 * easeOut;
        
        const timeoutId = setTimeout(() => {
          if (Math.abs(deltaX) > 0.01 || Math.abs(deltaY) > 0.01) {
            this.inputHandlers.sendScroll(Math.round(deltaX), Math.round(deltaY));
          }
          // Remove the timeout ID from the array once it's complete
          this.activeTimeouts = this.activeTimeouts.filter(id => id !== timeoutId);
        }, i * 16);
        
        this.activeTimeouts.push(timeoutId);

        currentVelocityX *= MOMENTUM_DECAY_FACTOR;
        currentVelocityY *= MOMENTUM_DECAY_FACTOR;
      }
    }
    
    this.velocityX = 0;
    this.velocityY = 0;
  };
} 