import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createBrowserHistory, History } from "history";
import * as React from "react";
import { Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useConfigContext } from "../webappConfig";
import { createTheme, ThemeProvider } from "@mui/material";

export type ProviderType = {
  children: React.ReactNode;
};

type CustomRouterProps = {
  history: History;
  children: React.ReactNode;
};

const CustomRouter: React.FC<CustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export const HISTORY = createBrowserHistory() as History;

const globalTheme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeightRegular: 500,
    fontWeightBold: 700,
    h3: {
      fontSize: "22pt",
      fontWeight: 800,
      lineHeight: "24pt",
    },
    h4: {
      fontSize: "20pt",
      fontWeight: 700,
      lineHeight: "22pt",
    },
    h5: {
      fontSize: "16pt",
      fontWeight: 600,
      lineHeight: "20pt",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 16px -4px rgba(214, 229, 243, 1)",
          backgroundColor: "white",
          height: "80px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: "center",
          minHeight: "80px",
        },
      },
    },
  },
});

export const Providers: React.FC<ProviderType> = (props) => {
  return (
    <ThemeProvider theme={globalTheme}>
      <CustomRouter history={HISTORY}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Auth0Provider
            domain={getReactAppEnvVar("auth0_domain")}
            clientId={getReactAppEnvVar(`auth0_client_id`)}
            authorizationParams={{
              redirect_uri: `${window.location.origin}/app/sessions`,
              audience: getReactAppEnvVar("auth0_audience"),
            }}
          >
            <GraphQLProvider>{props.children}</GraphQLProvider>
          </Auth0Provider>
        </QueryParamProvider>
      </CustomRouter>
    </ThemeProvider>
  );
};

export const getReactAppEnvVar = (name: string) => {
  const value = process.env[`REACT_APP_${name.toUpperCase()}`];
  if (!value) throw Error(`missing env var - ${name}`);
  return value;
};

type GraphQLProviderProps = {
  children: React.ReactNode;
};

const GraphQLProvider: React.FC<GraphQLProviderProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();

  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  const loadAccessToken = async () => {
    try {
      setAccessToken(await getAccessTokenSilently());
    } catch (error) {
      setAccessToken("");
    }
  };

  React.useEffect(() => {
    loadAccessToken();
  }, []);

  const client = new ApolloClient({
    uri: apiBaseUrl() + "/graphql",
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    connectToDevTools: false,
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
